import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "lib/axios";
import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Tooltip } from "react-tooltip";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NavHeader } from "components/layout/NavHeader";
import { InvoiceHeader } from "components/layout/InvoiceHeader";
import { Spinner } from "components/common/Spinner";
import { formatDate } from "utils/format";
import { Status } from "assets/status";
import { useInvoicePaginationActions } from "store/invoicePagination";
import useHomePagination from "store/homePagination";
import { HomePagination } from "components/layout/HomePagination";
import { useGlobalFilterParams } from "store/globalFIlter";
import ColorIndications from "assets/colorIndications";
import { PatchCheckFill } from "react-bootstrap-icons";
import { PlusCircleFill } from "react-bootstrap-icons";
import useUpdateSearchParams from "hooks/useUpdateSearchParams";
import Skeleton from "components/common/Skeleton";
const useHomeTableData = () => {
  const page_index = useHomePagination((state) => state.pageNumber);
  const [searchParams] = useSearchParams();
  const filters = searchParams.get("filters") ?? "{}";
  return useQuery({
    queryKey: ["list_invoices", page_index, ...Object.values(filters)],
    queryFn: async () => {
      const response = await axiosInstance.post(
        "/list_invoices",
        JSON.parse(filters)
      );
      const invoices = response.data.invoices.map((invoice) => ({
        ...invoice,
        human_verification_date: formatDate(invoice.human_verification_date),
        load_date: formatDate(invoice.load_date)
      }));
      return {
        totalPages: response.data.total_pages,
        invoices
      };
    },
    enabled: filters !== "{}"
    // placeholderData: keepPreviousData,
  });
};

const TH = ({ children }) => (
  <th
    className="text-center p-2"
    style={{ backgroundColor: "#FFF2CD", verticalAlign: "middle" }}
  >
    {children}
  </th>
);

const stopPropagation = (event) => {
  event.preventDefault();
  event.stopPropagation();
  event.nativeEvent.stopImmediatePropagation();
};

export const HomeTable = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filters = searchParams.get("filters") ?? "{}";
  const updateSearchParams = useUpdateSearchParams();
  useEffect(() => {
    if (filters == "{}") {
      updateSearchParams({
        pdf_index: 1,
        page_index: 1,
        invoice_size: 8,
        filters: {
          human_verified: "both",
          human_verification: "both",
          invoice_detection_status: "all",
          rerun_status: "both",
          invoice_type: "all",
          start_date: null,
          end_date: null,
          vendor_name: null,
          restaurant_id: null,
          auto_accepted: "both"
        }
      });
    }
  }, [filters]);
  const invoiceActions = useInvoicePaginationActions();
  const { data, isPending, isLoading, isError, error } = useHomeTableData();
  const [reasonsVisibility, setReasonsVisibility] = useState(
    Array(11).fill(false)
  );

  const handleReasonsToggle = (e, i) => {
    stopPropagation(e);
    setReasonsVisibility((l) => l.map((e, j) => (j === i ? !e : e)));
  };

  return (
    <>
      <NavHeader />
      <InvoiceHeader />
      <div
        className="mx-5"
        style={{
          marginTop: isPending ? "0px" : "35px"
        }}
      >
        {isPending || isLoading ? (
          // <Spinner />
          <></>
        ) : isError && (
          (error?.response?.status == 401 && navigate("/login"),
          "En Error Occurred: " + error?.message)
        ) }
          <>
            <ColorIndications />
            <Table
              striped
              bordered
              hover
              variant="light"
              responsive
              className="shadow-sm"
            >
              <thead>
                <tr>
                  <TH>InvoiceID</TH>
                  <TH>Source/Channel</TH>
                  <TH>Restaurant</TH>
                  <TH>Vendor</TH>
                  <TH>Load Date</TH>
                  <TH>Auto Balance Status</TH>
                  <TH>Manual Balance Status</TH>
                  <TH>Final Status</TH>
                  <TH>clickBACON Status</TH>
                  <TH>Failure Cause Code</TH>
                  <TH>Accepted/Rejected</TH>
                  <TH>Rejected Reasons</TH>
                  <TH>Invoice Type</TH>
                  <TH>Human Verification Date</TH>
                </tr>
              </thead>
              <tbody >
                {isLoading || isPending
                  ? [0, 1, 2, 3, 4,5,6,7].map((_, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>

                          <td
                            style={{
                              height: "7vh",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                              paddingTop: 30
                            }}
                          >
                            <Skeleton />
                          </td>
                        </tr>
                      );
                    })
                  : data?.invoices.map((item, i) => (
                      <>
                        <tr
                          onClick={() => {
                            const p = item?.invoice_index ?? 0;
                            invoiceActions.setTempValue(p);
                            invoiceActions.setPageNumber(p);
                            updateSearchParams({
                              pdf_index: p,
                              invoice_size: 8
                            });
                            let newFilters = {
                              ...JSON.parse(filters),
                              pdf_index: p
                            };
                            const filtersString = JSON.stringify(newFilters);
                            const encodedFilters =
                              encodeURIComponent(filtersString);
                            navigate(`/details?filters=${encodedFilters}`);
                          }}
                          key={`${item.invoice_index}${i}`}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                        >
                          <Tooltip id={item?.invoice_index} />
                          <td>{item.invoice_id}</td>
                          <td>{item.channel}</td>
                          <td>{item.restaurant_name}</td>
                          <td>
                            {item.vendor}
                            {item.verified_vendor && (
                              <PatchCheckFill
                                style={{ color: "blue", marginLeft: "5px" }}
                              />
                            )}
                          </td>
                          <td>{item.load_date}</td>
                          <td>
                            <Status>{item.auto_balance_status}</Status>
                          </td>
                          <td>
                            <Status>{item.manual_balance_status}</Status>
                          </td>
                          <td>
                            <Status>{item.final_status}</Status>
                          </td>
                          <td>{item.clickbacon_status}</td>
                          <td style={{ verticalAlign: "top" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill={
                                item.failure_cause_code === 0 ||
                                item.failure_cause_code === 1 ||
                                item.failure_cause_code === 2 ||
                                item.failure_cause_code === 3 ||
                                item.failure_cause_code === 4
                                  ? "red"
                                  : item.failure_cause_code === 5
                                  ? "#FFEF00"
                                  : item.failure_cause_code === 6
                                  ? "orange"
                                  : item.failure_cause_code === -1
                                  ? "green"
                                  : "black"
                              }
                            >
                              <circle
                                fill={
                                  item.failure_cause_code === 0 ||
                                  item.failure_cause_code === 1 ||
                                  item.failure_cause_code === 2 ||
                                  item.failure_cause_code === 3 ||
                                  item.failure_cause_code === 4
                                    ? "red"
                                    : item.failure_cause_code === 5
                                    ? "#FFEF00"
                                    : item.failure_cause_code === 6
                                    ? "orange"
                                    : item.failure_cause_code === -1
                                    ? "green"
                                    : "black"
                                }
                                cx="8"
                                cy="8"
                                r="8"
                              />
                            </svg>
                          </td>
                          <td style={{ textTransform: "capitalize" }}>
                            {item?.accepted_or_rejected}
                          </td>
                          <td>{item?.rejected_reason}</td>
                          {/* <td className="text-center">
                        <button
                          className="btn btn-warning"
                          disabled={item.reasons?.length === 0}
                          onClick={(e) => handleReasonsToggle(e, i)}
                          // data-toggle="collapse"
                          // data-target={`${item.invoice_index}reasons`}
                        >
                          View
                        </button>
                      </td> */}
                          <td>{item.invoice_type}</td>
                          <td>{item.human_verification_date}</td>
                        </tr>
                        {reasonsVisibility[i] &&
                          item.reasons.map((r, j) => (
                            <tr key={j}>
                              <td align="center" colspan="11">
                                {r}
                              </td>
                            </tr>
                          ))}
                      </>
                    ))}
                <tr>
                  <td colSpan="14" className="text-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/create-invoice")}
                    >
                      <PlusCircleFill className="mx-2" />
                      Create Invoice
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <HomePagination totalInvoices={data?.totalPages} />
          </>
        
      </div>
    </>
  );
};
