import {Dropdown} from "react-bootstrap";
import useGlobalFilterStore, {
	verificationFilterOptions,
	invoiceFilterOptions,
	detectionFilterOptions,
	rerunFilterOptions,
	verificationFilterMap,
	detectionFilterMap,
	rerunFilterMap,
	invoiceFilterMap,
	autoAcceptedFilterOptions,
	autoAcceptedFilterMap
} from "store/globalFIlter";

import {DateRange} from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {useSearchParams} from "react-router-dom";
import useUpdateSearchParams from "hooks/useUpdateSearchParams";
import useGetNestedFilterValue from "hooks/useGetNestedFilterValue";
import {useEffect} from "react";
export const FilterDropdown = () => {
	const {
		verification,
		invoiceType,
		dateRange,
		invoiceDetectionStatus,
		invoiceRerunStatus,
		autoAcceptedFilter,
		actions: {
			setVerification,
			setInvoiceType,
			setDateRange,
			setInvoiceDetectionStatus,
			setInvoiceRerunStatus,
			setAutoAcceptedFilter
		}
	} = useGlobalFilterStore();
	const updateSearchParams = useUpdateSearchParams();
	const getNestedFilterValue = useGetNestedFilterValue();
	const [searchParams] = useSearchParams();
	const human_verification = getNestedFilterValue(["filters", "human_verification"]);
	const findKeyByValue = (obj, targetValue) => {
		return Object.keys(obj).find((key) => obj[key] === targetValue);
	};

	useEffect(() => {
		setDateRange(getNestedFilterValue(["filters", "start_date"]) && getNestedFilterValue(["filters", "end_date"]) ? [{
				startDate: new Date(getNestedFilterValue(["filters", "start_date"])),
				endDate: new Date(getNestedFilterValue(["filters", "end_date"])),
				key: "selection"
			}] : dateRange);
	}, []);
	const formatDate = (date) => {
		const localDate = new Date(date);

		// Extract year, month, and day as per local date
		const year = localDate.getFullYear();
		const month = String(localDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
		const day = String(localDate.getDate()).padStart(2, "0");

		return `${year}-${month}-${day}`;
	};
	return (
		<Dropdown autoClose={"outside"}>
			<Dropdown.Toggle variant="outline" className="border w-100 h-100 bg-white"
				style={
					{
						lineHeight: "1.2",
						fontSize: "14px"
					}
			}>
				Filter
			</Dropdown.Toggle>
			<Dropdown.Menu className="flex-d flex-col p-2"
				style={
					{
						gap: "10px",
						maxHeight: "calc(100vh - 80px)",
						overflowY: "auto"
					}
			}>
				<label htmlFor="verification">Human Verification</label>
				<select id="verification"
					style={
						{flex: "1 1 0%"}
					}
					className="form-select flex-1"
					onChange={
						(e) => {
							setVerification(e.target.value);
							// setSearchParams({verification: e.target.value})
							updateSearchParams({
								filters: {
									human_verification: verificationFilterMap[e.target.value]
								}
							});
						}
					}
					// value={verification}
					value={
						findKeyByValue(verificationFilterMap, getNestedFilterValue(["filters", "human_verification"]))
				}>
					{
					verificationFilterOptions.map((option) => (
						<option key={option}
							value={option}>
							{option} </option>
					))
				} </select>
				<label htmlFor="invoiceType">Invoice Type</label>
				<select id="invoiceType"
					style={
						{flex: "1 1 0%"}
					}
					className="form-select flex-1"
					onChange={
						(e) => {
							setInvoiceType(e.target.value);
							// setSearchParams({invoiceType: e.target.value})
							updateSearchParams({
								filters: {
									invoice_type: invoiceFilterMap[e.target.value]
								}
							});
						}
					}
					value={
						findKeyByValue(invoiceFilterMap, getNestedFilterValue(["filters", "invoice_type"]))
				}>
					{
					invoiceFilterOptions.map((option) => (
						<option key={option}
							value={option}>
							{option} </option>
					))
				} </select>
				<label htmlFor="invoiceDetectionStatus">Invoice Detection Status</label>
				<select id="invoiceDetectionStatus"
					style={
						{flex: "1 1 0%"}
					}
					className="form-select flex-1"
					onChange={
						(e) => {
							setInvoiceDetectionStatus(e.target.value);
							// setSearchParams({invoiceDetectionStatus: e.target.value})
							updateSearchParams({
								filters: {
									invoice_detection_status: detectionFilterMap[e.target.value]
								}
							});
						}
					}
					value={
						findKeyByValue(detectionFilterMap, getNestedFilterValue(["filters", "invoice_detection_status"]))
				}>
					{
					detectionFilterOptions.map((option) => (
						<option key={option}
							value={option}>
							{option} </option>
					))
				} </select>
				<label htmlFor="invoiceRerunStatus">Invoice Re-Run Status</label>
				<select id="invoiceRerunStatus"
					style={
						{flex: "1 1 0%"}
					}
					className="form-select flex-1"
					onChange={
						(e) => {
							setInvoiceRerunStatus(e.target.value);
							// setSearchParams({invoiceRerunStatus: e.target.value})
							updateSearchParams({
								filters: {
									rerun_status: rerunFilterMap[e.target.value]
								}
							});
						}
					}
					value={
						findKeyByValue(rerunFilterMap, getNestedFilterValue(["filters", "rerun_status"]))
				}>
					{
					rerunFilterOptions.map((option) => (
						<option key={option}
							value={option}>
							{option} </option>
					))
				} </select>
				<label htmlFor="invoiceRerunStatus">Auto Accepted Filter</label>
				<select id="invoiceRerunStatus"
					style={
						{
							flex: "1 1 0%",
							textTransform: "capitalize"
						}
					}
					className="form-select flex-1"
					onChange={
						(e) => {
							setAutoAcceptedFilter(e.target.value);
							updateSearchParams({
								filters: {
									auto_accepted: autoAcceptedFilterMap[e.target.value]
								}
							});
						}
					}
					value={
						findKeyByValue(autoAcceptedFilterMap, getNestedFilterValue(["filters", "auto_accepted"]))
				}>
					{
					autoAcceptedFilterOptions.map((option) => (
						<option key={option}
							value={option}>
							{option} </option>
					))
				} </select>
				<label htmlFor="clickBACONStatus">clickBACON Status</label>
				<select id="clickBACONStatus"
					style={
						{
							flex: "1 1 0%",
							textTransform: "capitalize"
						}
					}
					className="form-select flex-1"
					onChange={
						(e) => { // setSearchParams({clickBACONStatus: e.target.value})
							updateSearchParams({
								filters: {
									clickbacon_status: e.target.value
								}
							});
						}
					}
					value={
						getNestedFilterValue(["filters", "clickbacon_status"]) || "all"
				}>
					{
					[
						{
							key: "All",
							value: "all"
						},
						{
							key: "Pending",
							value: "pending"
						},
						{
							key: "Approved",
							value: "approved"
						},
						{
							key: "Rejected",
							value: "rejected"
						}, {
							key: "Synced",
							value: "synced"
						}, {
							key: "Manually Synced",
							value: "manually_synced"
						}, {
							key: "Rereview Requested",
							value: "re_review_requested"
						}
					].map(({key, value}) => (
						<option key={key}
							value={value}
							style={
								{textTransform: "capitalize"}
						}>
							{key} </option>
					))
				} </select>
				<label htmlFor="dateRange">Date Range</label>
				{" "}
				<span className="text-muted"
					style={
						{
							fontSize: "12px",
							position: "absolute",
							right: "10px",
							textDecoration: "underline",
							cursor: "pointer"
						}
					}
					onClick={
						() => {
							setDateRange([{
									startDate: null,
									endDate: null,
									key: "selection"
								}]);
							// setSearchParams({startDate: null, endDate: null})
							updateSearchParams({
								filters: {
									start_date: null,
									end_date: null
								}
							});
						}
				}>
					Reset
				</span>
				<DateRange id="dateRange"
					editableDateInputs={true}
					onChange={
						(item) => {
							setDateRange([item.selection]);
							// Format the selected start and end dates
							const startDate = formatDate(item.selection.startDate);
							const endDate = formatDate(item.selection.endDate);
							updateSearchParams({
								filters: {
									start_date: startDate,
									end_date: endDate
								}
							});
						}
					}
					moveRangeOnFirstSelection={false}
					ranges={dateRange}/>
			</Dropdown.Menu>
	</Dropdown>
	);
};
